
export default {
	name: 'plProjectParticulars',
	data() {
		return {
			sex: '',
			newEdit: false,
			projectName: '',//标题
			crcUserList: '',//列表
			departmentName: '',//科室
			diseaseName: '',//适应症
			startTime: '',
			endTime: '',
			removeAlert: false,
			outId: "",
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.getProPar()
	},

	methods: {
		getProPar() {
			var _this = this;
			_this.$http.post('/API/PLAT/projectInfo', {
				pid: _this.$route.params.id
			}, {
				headers: { "token": _this.token }
			})
				.then(function (response) {
					//						console.log(response)
					if (response.data.code == 200) {
						//						console.log(response)
						_this.crcUserList = response.data.data.crcUserList;
						_this.projectName = response.data.data.projectName;
						_this.departmentName = response.data.data.departmentName;
						_this.diseaseName = response.data.data.diseaseName;
						_this.startTime = response.data.data.startTime;
						_this.endTime = response.data.data.endTime;
					}
				})
		},
		outProject(id) {
			this.outId = id;
			this.removeAlert = true;
		},
		yes() {
			this.removeAlert = false;
			var _this = this;
			_this.$http.post('/API/PLAT/outProject', {
				pid: _this.$route.params.id,
				crcUserId: _this.outId
			}, {
				headers: { "token": _this.token }
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.getProPar()
					}
				})
		},
		no() {
			this.removeAlert = false;
		},
		toPl() {
			this.$router.push('/platformStatistics')
		},
		//跳转CRC详情
		toPar(id) {
			this.$router.push({ name: 'plCrcParticulars', params: { id: id } });
		},
	}
}
